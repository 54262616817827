import {
  HOMEPAGE,
  ANALYTICS,
  ACCOUNT_DETAILS,
  ACCOUNT_SETTINGS,
  ACCOUNT_CONTACT,
  FOLDERS,
  FOLDERS_ALL,
  FOLDERS_ID,
  TOS,
  BULK_UPLOAD,
  WIDGET_LOOKBOOK,
} from '@/utils/router/namedRoutes';

export const zendeskPageAllowList = [
  HOMEPAGE,
  ANALYTICS,
  ACCOUNT_DETAILS,
  ACCOUNT_SETTINGS,
  ACCOUNT_CONTACT,
  FOLDERS,
  FOLDERS_ALL,
  FOLDERS_ID,
  TOS,
  BULK_UPLOAD,
  WIDGET_LOOKBOOK,
];

export function zendeskAllowedOnPage(route: string): boolean {
  return zendeskPageAllowList.includes(route);
}
