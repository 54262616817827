
import { PropType, computed, defineComponent } from 'vue';
import { uuid } from '../../utils';
import useKeyboardFocus from '../../composables/useKeyboardFocus';
import useCheckbox from '../../composables/useCheckbox';

type ValidationRule = (value: any) => any;

export default defineComponent({
  name: 'RwCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
    },
    value: {
      type: [String, Number, Boolean, Object, Array] as PropType<any>,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    required: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
    trueValue: {
      type: [Boolean, String, Number] as PropType<boolean | string | number>,
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number] as PropType<boolean | string | number>,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: () => `rw-checkbox--${uuid()}`,
    },
    rules: {
      type: Array as PropType<ValidationRule[]>,
      default: () => [],
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    dataTestid: {
      type: String,
      default: 'rw-checkbox-input',
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'error', 'checked', 'unchecked', 'click'],
  setup(props, { emit }) {
    const { checkboxRef, isError, errorMessage, isChecked, onChecked, isDirty } = useCheckbox(props, emit);

    const hasFocus = useKeyboardFocus(checkboxRef);
    const attributes = computed(() => {
      const attrs = {
        id: props.id,
        name: props.name,
        disabled: props.disabled,
        required: props.required,
        value: props.value as string | null | undefined,
        'true-value': props.trueValue,
        'false-value': props.falseValue,
      };
      if (props.value === null || typeof props.value !== 'object')
        attrs.value = props.value === null || props.value === undefined ? '' : String(props.value);

      return attrs;
    });

    const containerClasses = computed(() => {
      return {
        'rw--checkbox': true,
        'is--disabled': props.disabled,
        'is--sm': props.size === 'sm',
        'is--md': props.size === 'md',
        'is--lg': props.size === 'lg',
        'is--full': props.full,
        'is--error': isError.value,
        'is--dark': props.dark,
        'is--solo': props.solo,
      };
    });

    const inputClasses = computed(() => {
      return {
        'rw--checkbox__field': true,
        'is--checked': isChecked.value,
        'is--disabled': props.disabled,
        'is--required': props.required,
        'is--indeterminate': props.indeterminate,
        'is--focused': hasFocus.value,
      };
    });

    return {
      errorMessage,
      checkboxRef,
      attributes,
      inputClasses,
      containerClasses,
      isChecked,
      onChecked,
      isError,
      isDirty,
    };
  },
});
