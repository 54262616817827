import { RouteConfig } from 'vue-router';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import { ExperimentMeta } from '@/types/experiments';
import { WIDGET_LOOKBOOK } from '@/utils/router/namedRoutes';

const moneyspotExperimentMeta: ExperimentMeta = {
  name: ExperimentName.MONEYSPOT_FEATURE_FLAG,
  type: ExperimentType.FLAG,
  allowed: ['on'],
};

const routes: RouteConfig[] = [
  {
    path: '/widgets/lookbook',
    name: WIDGET_LOOKBOOK,
    component: () => import(/* webpackChunkName: "lookbook" */ '../lookbook/views/Lookbook.vue'),
    meta: {
      useExchangeRate: true,
    },
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
  },
  {
    path: '/widgets/money-spots',
    component: () => import(/* webpackChunkName: "moneyspot" */ '../moneyspot/views/Moneyspots.vue'),
    meta: {
      useExchangeRate: true,
      experiment: moneyspotExperimentMeta as ExperimentMeta,
    },
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
  },
  {
    path: '/widgets/money-spots/:id',
    component: () => import(/* webpackChunkName: "moneyspot" */ '../moneyspot/views/Moneyspot.vue'),
    props: true,
    meta: {
      useExchangeRate: true,
      experiment: moneyspotExperimentMeta as ExperimentMeta,
    },
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
  },
];

export default routes;
