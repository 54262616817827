import { NavigationGuard } from 'vue-router';
import {
  HOME,
  RESET_PASSWORD,
  ACCOUNT_SETUP,
  isLegalRoute,
  isSetupRoute,
  VERIFY_EMAIL,
} from '@/utils/router/namedRoutes';
import AccountsStore from '@/store/accounts';
import UserInfoStore from '@/store/userInfo';
import { UserStatus } from '@/utils/decodeUserStatus';
import { redirectToAccountSetup } from '@/rs-account-setup/utils/redirectToAccountSetup';
import ProfilesStore from '@/store/profiles';
import { auth } from '@/utils/auth';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import { useExperimentsStore } from '@/store/experiments';

export const checkUserStatus: NavigationGuard = async function (to, from, next) {
  // Allow skipping status checks in routes like /logout
  const requiresStatusCheck: boolean | undefined = to.meta?.statusCheck?.required ?? true;
  if (!requiresStatusCheck) return next();

  // Users who have not verified their emails will be pushed to email verification
  // We do this check before fetching and user data because there won't be any at this point
  // Experiment check (feature flag)
  const { useExperiment } = useExperimentsStore();
  const { isVariant } = useExperiment(ExperimentName.APPLICATION_SIGNUP_LOGIN, ExperimentType.FLAG);
  const isAslExperimentEnabled = isVariant('on');

  const idTokenClaims = await auth.getIdTokenClaims();

  if (isAslExperimentEnabled.value && idTokenClaims?.email_verified === false) {
    // Check where the user is heading to, and omit the name if it is already VERIFY_EMAIL,
    // so user doesn't get stuck in an infinite loop
    return to.name === VERIFY_EMAIL ? next() : next({ name: VERIFY_EMAIL });
  }

  const [fetchAccountUser, fetchUserInfo, hasProfile] = await Promise.all([
    AccountsStore.fetchAccountUser({}),
    UserInfoStore.fetchUserInfo({}),
    ProfilesStore.fetchProfile({}),
  ]);

  const { COLLABORATIONS_PAGE } = window.rewardStyle;

  /**
   * Check Authorization
   */

  // Unauthorized user: Hard redirect to Login
  if (UserInfoStore.unauthorized) {
    return auth.login({
      options: {
        appState: {
          target: 'https://' + window.location.host + '/home',
        },
      },
      next: 'https://' + window.location.host + '/home',
    });
  }

  /**
   * Check Blacklist Status - FOR AGENTS
   */

  // Blacklisted user: logged out and redirect to collabs
  if (AccountsStore.user.is_blacklisted) {
    await auth.logout();
    return (window.location.href = COLLABORATIONS_PAGE);
  }

  /**
   * Check Status: Account Setup, Terms of Service, Password Change, etc.
   */

  // Legal pages: Must be accessible despite any and all (authorized) user states
  if (isLegalRoute(to)) return next();

  const requiresAccountSetup = redirectToAccountSetup(to, {
    country: AccountsStore.user.address.country,
    isOwner: AccountsStore.user.is_owner,
    needsToAcceptTerms: AccountsStore.user.needs_accept_terms,
    hasCompleteStatus: AccountsStore.userStatusRoles.includes(UserStatus.COMPLETE),
    hasProfile,
  });

  if (requiresAccountSetup) {
    return next({ name: ACCOUNT_SETUP });
  }

  const requiresPasswordChange =
    !isSetupRoute(to) && UserInfoStore.userInfo.require_password_change && to.name !== RESET_PASSWORD; // Excluding RESET_PASSWORD so we don't get stuck in an infinite loop

  const redirectPasswordChange = !UserInfoStore.userInfo.require_password_change && to.name === RESET_PASSWORD;

  if (requiresPasswordChange) {
    return next({ name: RESET_PASSWORD });
  } else if (redirectPasswordChange) {
    return next({ name: HOME });
  }

  return next();
};
