import { Route } from 'vue-router';

export const BASE = 'base';
export const HOME = 'home';
export const HOMEPAGE = 'homepage';
export const SECURITY_TXT = 'security-txt';
export const NOT_FOUND = 'not-found';

export const LOGOUT = 'logout';

export const ACCOUNT = 'account';
export const ACCOUNT_DETAILS = 'account-details';
export const ACCOUNT_SETTINGS = 'account-settings';
export const ACCOUNT_CONTACT = 'account-contact';
export const ACCOUNT_PREFERENCES = 'account-preferences';

/**
 * Account Setup Routes
 */

export const ACCOUNT_SETUP = 'account-setup';
export const ACCOUNT_SETUP_UPDATED_TERMS = 'account-setup-updated-terms';
export const ACCOUNT_SETUP_TERMS = 'account-setup-terms';
export const ACCOUNT_SETUP_DETAILS = 'account-setup-details';
export const ACCOUNT_SETUP_PLATFORM = 'account-setup-platform-information';
export const ACCOUNT_SETUP_CREATE_SHOP = 'account-setup-create-shop';
export const ACCOUNT_SETUP_DONE = 'account-setup-done';
export const RESET_PASSWORD = 'reset-password';

export const isSetupRoute = (to: Route) =>
  to.name === ACCOUNT_SETUP ||
  to.name === ACCOUNT_SETUP_UPDATED_TERMS ||
  to.name === ACCOUNT_SETUP_TERMS ||
  to.name === ACCOUNT_SETUP_DETAILS ||
  to.name === ACCOUNT_SETUP_PLATFORM ||
  to.name === ACCOUNT_SETUP_CREATE_SHOP ||
  to.name === ACCOUNT_SETUP_DONE;

export const isResetPasswordRoute = (to: Route) => to.name === RESET_PASSWORD;

export const PRODUCTS = 'products';
export const ANALYTICS = 'analytics';
export const PRODUCTS_SEARCH = 'products-search';
export const LTK_PRODUCT_LINK = 'ltk-product-link';
export const FOLDERS = 'my-folders';
export const FOLDERS_ALL = 'all-saved';
export const FOLDERS_ID = 'folder-by-id';

/**
 * Account Creation Routes, pre-application
 */
export const CREATOR_SIGNUP = 'creator-signup';
export const EMAIL_CHECK = 'email-check';
export const EXISTING_ACCOUNT = 'existing-account';
export const VERIFY_EMAIL = 'verify';
export type ApplicationSignupPages = 'email-check' | 'existing-account' | 'verify';

/**
 * Application Routes
 **/
export const APPLY_INVITE = 'apply-invite';
export const INFLUENCER_APPLICATION = 'influencer-application';
export const APPLY_INFLUENCER = 'apply-influencer';

export const CREATOR_APPLICATION = 'creator-application';
export const APPLY_HOME = 'apply-home';
export const APPLY_ABOUT_YOU = 'apply-about-you';
export const APPLY_SOCIAL_CHANNELS = 'apply-social-channels';
export const APPLY_EXPERIENCE = 'apply-experience';
export const APPLY_DONE = 'apply-done';
export type ApplicationApplyPages =
  | 'apply-home'
  | 'apply-about-you'
  | 'apply-social-channels'
  | 'apply-experience'
  | 'apply-done';

export const APPLY_STATUS = 'apply-status';
export const APPLY_CONGRATS = 'apply-congrats';
export type ApplicationStatusPages = 'apply-status' | 'apply-congrats';

export type ApplicationPages = ApplicationSignupPages | ApplicationApplyPages | ApplicationStatusPages;

/**
 * Legal Routes
 */
export const LEGAL = 'legal';
export const TOS = 'tos';
export const PRIVACY = 'privacy';
export const SMS_TERMS = 'sms-terms';

export const isLegalRoute = (to: Route) =>
  to.name === LEGAL || to.name === TOS || to.name === PRIVACY || to.name === SMS_TERMS;

/**
 * Widget Routes
 */
export const WIDGETS = 'widgets';
export const WIDGET_LOOKBOOK = 'lookbook';
export const WIDGET_MONEYSPOT = 'moneyspot';

/**
 * Publish Routes
 */

export const PUBLISH_BASE = 'publish';
export const PUBLISH_LTK = 'publish-ltk';
export const PUBLISH_LTK_ID = 'publish-ltk-id';
export const EDIT = 'edit';
export const CAPTION_HASHTAG = 'caption-hashtag';
export const BULK_UPLOAD = 'bulk-upload';

/**
 * rs-social Routes
 */

export const RS_SOCIAL_INSTAGRAM = 'rs-social-instagram';

/**
 * rs-desktop-publish Routes
 */
export const DESKTOP_PUBLISH = 'desktop-publish';
export const DESKTOP_DRAFTS = 'desktop-drafts';
export const DESKTOP_SCHEDULED = 'desktop-scheduled';
