
import { computed, defineComponent, toRefs, watch } from 'vue';
import { zendeskAllowedOnPage } from './zendesk-page-allow';

export default defineComponent({
  name: 'ZendeskHelpChat',
  props: {
    routeName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { ZENDESK_KEY } = window.rewardStyle;
    const { routeName } = toRefs(props);
    const showZendesk = computed(() => Boolean(ZENDESK_KEY) && zendeskAllowedOnPage(routeName.value));

    watch(
      showZendesk,
      (newValue, oldValue) => {
        const hide = oldValue && !newValue;

        if (window.zE) {
          if (hide) {
            window?.zE('messenger', 'hide');
          } else {
            window?.zE('messenger', 'show');
          }
        }
      },
      { flush: 'post' },
    );

    return { showZendesk, ZENDESK_KEY };
  },
});
