const toSeconds = (value: string | number) => {
  if (typeof value === 'number') return value;
  const a = value.split(':');
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return seconds;
};

const toTime = (value: number | string) => {
  if (typeof value === 'string') return value;
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const secs = value % 60;

  const pad = (num: number) => num.toString().padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export { toSeconds, toTime };
