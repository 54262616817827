import { RouteConfig } from 'vue-router';
import {
  APPLY_INVITE,
  APPLY_INFLUENCER,
  INFLUENCER_APPLICATION,
  CREATOR_SIGNUP,
  EMAIL_CHECK,
  EXISTING_ACCOUNT,
  CREATOR_APPLICATION,
  APPLY_HOME,
  APPLY_ABOUT_YOU,
  APPLY_SOCIAL_CHANNELS,
  APPLY_EXPERIENCE,
  APPLY_DONE,
  VERIFY_EMAIL,
  APPLY_STATUS,
  APPLY_CONGRATS,
} from '@/utils/router/namedRoutes';

import { gettext } from '@/utils/fakeGetText';
import { Layout } from '@/layouts/types';
import LeavePagePopupLayout from '@/components/LeavePagePopupLayout.vue';

import { checkApplicationStatus } from '@/rs-application/router/navigation-guards/checkApplicationStatus';
import { checkApplicationComplete } from '@/rs-application/router/navigation-guards/checkApplicationComplete';
import { ExperimentMeta, ExperimentName, ExperimentType } from '@/types/experiments';
import { checkEmailVerificationStatus } from '@/rs-application/router/navigation-guards/checkEmailVerification';

const aslExperimentMeta: ExperimentMeta = {
  name: ExperimentName.APPLICATION_SIGNUP_LOGIN,
  type: ExperimentType.FLAG,
  allowed: ['on'],
  control: {
    name: CREATOR_APPLICATION,
  },
};

const routes: RouteConfig[] = [
  {
    path: '/apply/invite',
    name: APPLY_INVITE,
    component: () => import(/* webpackChunkName: "rs-application" */ '@/rs-application/views/Referrals.vue'),
    meta: {
      auth: {
        required: true,
        mismatchedRedirect: { name: CREATOR_APPLICATION },
      },
      title: {
        name: gettext('Have a friend who belongs on LTK?'),
        translate: true,
      },
      layout: Layout.REFERRALS,
    },
  },
  {
    // "Legacy" route used by marketing campaigns
    path: '/apply/influencer',
    name: APPLY_INFLUENCER,
    meta: {
      auth: {
        required: false,
        mismatchedRedirect: { name: APPLY_INVITE },
      },
      layout: Layout.APPLICATION,
    },
    redirect: { name: CREATOR_APPLICATION },
  },
  {
    // "Legacy" route
    path: '/apply/home',
    meta: {
      auth: {
        required: false,
        mismatchedRedirect: { name: APPLY_INVITE },
      },
      layout: Layout.APPLICATION,
    },
    redirect: { name: CREATOR_APPLICATION },
  },
  {
    // "Legacy" route used by our Referral Email
    path: '/apply',
    name: INFLUENCER_APPLICATION,
    meta: {
      auth: {
        required: false,
        mismatchedRedirect: { name: APPLY_INVITE },
      },
      layout: Layout.APPLICATION,
    },
    redirect: { name: CREATOR_APPLICATION },
  },
  {
    // Signup
    path: '/apply/signup',
    name: CREATOR_SIGNUP,
    component: () => import(/* webpackChunkName: "rs-application" */ '../views/signup/ApplicationSignup.vue'),
    meta: {
      auth: {
        required: false,
        mismatchedRedirect: { name: APPLY_INVITE },
      },
      experiment: aslExperimentMeta,
      layout: Layout.SIGNUP,
    },
    redirect: { name: EMAIL_CHECK }, // TODO: Will be replaced with routing guard CA-2972
    children: [
      {
        path: 'email-check',
        name: EMAIL_CHECK,
        meta: {
          auth: {
            required: false,
            mismatchedRedirect: { name: APPLY_INVITE },
          },
          experiment: aslExperimentMeta,
          title: {
            name: gettext('Apply to become an LTK Creator'),
            translate: true,
          },
          layout: Layout.SIGNUP,
        },
        component: () =>
          import(/* webpackChunkName: "rs-application" */ '../views/signup/steps/ApplicationSignupEmailCheck.vue'),
      },
      {
        path: 'existing-account',
        name: EXISTING_ACCOUNT,
        meta: {
          auth: {
            required: false,
            mismatchedRedirect: { name: APPLY_INVITE },
          },
          experiment: aslExperimentMeta,
          title: {
            name: gettext('Apply to become an LTK Creator'),
            translate: true,
          },
          layout: Layout.SIGNUP,
        },
        component: () =>
          import(/* webpackChunkName: "rs-application" */ '../views/signup/steps/ApplicationSignupExistingAccount.vue'),
      },
      {
        path: 'verify',
        name: VERIFY_EMAIL,
        beforeEnter: checkEmailVerificationStatus,
        meta: {
          auth: {
            required: true,
            mismatchedRedirect: { name: APPLY_HOME },
          },
          experiment: aslExperimentMeta,
          title: {
            name: gettext('Verify your email'),
            translate: true,
          },
          layout: Layout.APPLICATION, // This page is post-authentication so it should have the same header as the Application
        },
        component: () =>
          import(/* webpackChunkName: "rs-application" */ '../views/signup/steps/ApplicationSignupEmailVerify.vue'),
      },
      {
        path: 'status',
        name: APPLY_STATUS,
        meta: {
          auth: {
            required: true,
            mismatchedRedirect: { name: APPLY_HOME },
          },
          experiment: aslExperimentMeta,
          title: {
            name: gettext('Your application status'),
            translate: true,
          },
          layout: Layout.APPLICATION, // This page is post-authentication so it should have the same header as the Application
        },
        component: () =>
          import(/* webpackChunkName: "rs-application" */ '../views/signup/steps/ApplicationSignupStatus.vue'),
        props: (route) => ({ status: route.query.status || 'pending' }), // TODO: change with status check
      },
      {
        path: 'approved',
        name: APPLY_CONGRATS,
        meta: {
          auth: {
            required: true,
            mismatchedRedirect: { name: APPLY_HOME },
          },
          experiment: aslExperimentMeta,
          title: {
            name: gettext("You've been approved!"),
            translate: true,
          },
          layout: Layout.APPLICATION, // This page is post-authentication so it should have the same header as the Application
        },
        component: () =>
          import(/* webpackChunkName: "rs-application" */ '../views/signup/steps/ApplicationSignupStatus.vue'),
        props: {
          status: 'approved',
        },
      },
    ],
  },
  {
    path: '/apply/creator',
    component: LeavePagePopupLayout,
    children: [
      {
        path: '',
        name: CREATOR_APPLICATION,
        component: () => import(/* webpackChunkName: "rs-application" */ '../views/wizard/ApplicationWizard.vue'),
        meta: {
          auth: {
            required: false,
          },
          layout: Layout.APPLICATION,
        },
        beforeEnter: checkApplicationStatus,
        children: [
          {
            path: 'home',
            name: APPLY_HOME,
            component: () =>
              import(/* webpackChunkName: "rs-application" */ '../views/wizard/steps/ApplicationHome.vue'),
            meta: {
              auth: {
                required: false,
                mismatchedRedirect: { name: APPLY_INVITE },
              },
              title: {
                name: gettext('Apply to become an LTK Creator'),
                translate: true,
              },
              layout: Layout.APPLICATION,
            },
          },
          {
            path: 'about-you',
            name: APPLY_ABOUT_YOU,
            component: () =>
              import(/* webpackChunkName: "rs-application" */ '../views/wizard/steps/ApplicationAboutYou.vue'),
            meta: {
              auth: {
                required: false,
                mismatchedRedirect: { name: APPLY_INVITE },
              },
              title: {
                name: gettext('About You'),
                translate: true,
              },
              layout: Layout.APPLICATION,
            },
            beforeEnter: checkApplicationComplete,
          },
          {
            path: 'social-channels',
            name: APPLY_SOCIAL_CHANNELS,
            component: () =>
              import(/* webpackChunkName: "rs-application" */ '../views/wizard/steps/ApplicationSocialChannels.vue'),
            meta: {
              auth: {
                required: false,
                mismatchedRedirect: { name: APPLY_INVITE },
              },
              title: {
                name: gettext('Your Social Channels'),
                translate: true,
              },
              layout: Layout.APPLICATION,
            },
            beforeEnter: checkApplicationComplete,
          },
          {
            path: 'experience',
            name: APPLY_EXPERIENCE,
            component: () =>
              import(/* webpackChunkName: "rs-application" */ '../views/wizard/steps/ApplicationExperience.vue'),
            meta: {
              auth: {
                required: false,
                mismatchedRedirect: { name: APPLY_INVITE },
              },
              title: {
                name: gettext('Your Experience'),
                translate: true,
              },
              layout: Layout.APPLICATION,
            },
            beforeEnter: checkApplicationComplete,
          },
          {
            path: 'done',
            name: APPLY_DONE,
            component: () =>
              import(/* webpackChunkName: "rs-application" */ '../views/wizard/steps/ApplicationDone.vue'),
            meta: {
              auth: {
                required: false,
                mismatchedRedirect: { name: APPLY_INVITE },
              },
              title: {
                name: gettext('Application Sent'),
                translate: true,
              },
              layout: Layout.APPLICATION,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
