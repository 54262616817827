import { defineStore } from 'pinia';
import { MediaUploadMap, MediaObjectProgressMap, UploadProgress } from '@/types/ltks';
import { computed, ref } from 'vue';
import { FileWithImageData } from '@/types/ltks';
import { type SelectedFile, type SelectedMediaUrl } from '@/types/webUpload';

export const useMediaObjectsStore = defineStore('Media Objects', () => {
  // Initial State
  const mediaObjectProgress = ref<MediaObjectProgressMap>({});
  const mediaUploads = ref<MediaUploadMap>({});
  const successfulMediaUploads = ref<(string | File | undefined)[]>([]);

  const posterFile = ref<File | null>(null);
  const selectedImage = ref<File | null>(null);
  const selectedImageURL = ref<string | undefined>(undefined);
  const selectedVideo = ref<string>('');
  const selectedVideoFileList = ref<FileList | null>(null);
  const selectedVideoPoster = ref<string | undefined>(undefined);
  const videoFile = ref<File | null>(null);
  const keyframes = ref<string[]>([]);
  const selectionFrames = ref<string[]>([]);
  const originalVideoResolution = ref<string[]>([]);
  const originalImageResolution = ref<string[]>([]);
  const originalMediaResolution = ref<string[]>([]);

  // Storytelling
  const selectedMediaFiles = ref<SelectedFile[]>([]);
  const inProcessMediaURLS = ref<SelectedMediaUrl[]>([]);
  const processedMediaURLS = ref<SelectedMediaUrl[]>([]);
  const selectedMediaIndex = ref<number>(0);

  const setInProcessMediaURLS = (media: SelectedMediaUrl) => {
    inProcessMediaURLS.value.push(media);
    inProcessMediaURLS.value.sort((a, b) => a.originalIndex - b.originalIndex);
  };

  const setProcessedMediaURLS = (media: SelectedMediaUrl) => {
    processedMediaURLS.value.push(media);
    processedMediaURLS.value.sort((a, b) => a.originalIndex - b.originalIndex);
  };

  const setSelectedMediaIndex = (index: number) => {
    selectedMediaIndex.value = index;
  };

  const setMuted = (muted: boolean) => {
    inProcessMediaURLS.value[selectedMediaIndex.value].muted = muted;
    processedMediaURLS.value[selectedMediaIndex.value].muted = muted;
  };

  const setPoster = (poster: string) => {
    inProcessMediaURLS.value[selectedMediaIndex.value].poster = poster;
    processedMediaURLS.value[selectedMediaIndex.value].poster = poster;
  };

  const saveInProcessMedia = () => {
    inProcessMediaURLS.value[selectedMediaIndex.value] = { ...processedMediaURLS.value[selectedMediaIndex.value] };
  };

  const saveInProcessMediaURL = () => {
    inProcessMediaURLS.value[selectedMediaIndex.value].url = processedMediaURLS.value[selectedMediaIndex.value].url;
  };

  const replaceSelectedMediaFiles = (media: SelectedFile) => {
    selectedMediaFiles.value[selectedMediaIndex.value] = {
      ...media,
    };
  };

  const replaceMediaURLS = (media: SelectedMediaUrl) => {
    inProcessMediaURLS.value[selectedMediaIndex.value] = {
      ...media,
    };
    processedMediaURLS.value[selectedMediaIndex.value] = {
      ...media,
    };
  };

  const getProcessedMediaUrl = () => {
    return processedMediaURLS.value?.[selectedMediaIndex.value]?.url;
  };

  const getSelectedMediaFile = () => {
    return selectedMediaFiles.value?.[selectedMediaIndex.value]?.file;
  };

  const setOriginalVideoResolution = (video: HTMLVideoElement) => {
    originalVideoResolution.value.push(`${video.videoWidth} x ${video.videoHeight}`);
  };

  const setOriginalImageResolution = (image: HTMLImageElement) => {
    originalImageResolution.value.push(`${image.naturalWidth} x ${image.naturalHeight}`);
  };

  const setOriginalMediaResolution = (media: HTMLImageElement | HTMLVideoElement) => {
    const resolution =
      media instanceof HTMLImageElement
        ? `${media.naturalWidth} x ${media.naturalHeight}`
        : `${media.videoWidth} x ${media.videoHeight}`;
    originalMediaResolution.value.push(resolution);
  };

  const setMediaObjectProgress = (ltkId: string, mediaObject: UploadProgress) => {
    mediaObjectProgress.value = Object.assign({}, mediaObjectProgress.value, {
      [ltkId]: { ...mediaObject },
    });
  };

  const getMediaObjectProgressByLtkId = (ltkId: string) => {
    return computed(() => mediaObjectProgress.value[ltkId]);
  };

  const getUriByLtkId = (ltkId: string) => {
    return computed(() => mediaUploads.value[ltkId]);
  };

  const $reset = () => {
    mediaObjectProgress.value = {};
    mediaUploads.value = {};
    successfulMediaUploads.value = [];
    resetImage();
    resetVideo();
  };

  const handleVideo = async (file: File) => {
    return URL.createObjectURL(file);
  };

  const setMediaUploads = async (ltkId: string, file: File) => {
    const mediaUpload = file.type.split('/')[0] === 'video' ? file : await handleImage(file);
    mediaUploads.value = Object.assign({}, mediaUploads.value, {
      [ltkId]: [mediaUpload],
    });
  };

  const setMediaUploadsDesktop = async (ltkId: string, media: File | string) => {
    let mediaUpload;
    if (media instanceof File) {
      mediaUpload = media.type.split('/')[0] === 'video' ? media : await handleImage(media);
    } else {
      mediaUpload = media;
    }

    mediaUploads.value = Object.assign({}, mediaUploads.value, {
      [ltkId]: [mediaUpload],
    });
  };

  const setSuccessfulMediaUploads = (media: string | File | undefined) => {
    if (!media) return;
    successfulMediaUploads.value?.push(media);
  };

  const handleImage = async (file: File) => {
    const reader = new FileReader();
    await new Promise((res, rej) => {
      reader.addEventListener('load', res);
      reader.addEventListener('error', rej);
      reader.readAsDataURL(file);
    });
    return (reader.result as string | null) || undefined;
  };

  const addImageData = async (files: File[]): Promise<FileWithImageData[]> => {
    const filesWithImages = await Promise.all(
      files.map(async (file) => {
        let imageData;
        if (file.type.split('/')[0] === 'video') {
          imageData = (await handleVideo(file)) || '';
        } else {
          imageData = (await handleImage(file)) || '';
        }

        // Explicitly create a new File object, rather than using spread operator to support typing
        const newFile = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        const fileWithImageData: FileWithImageData = Object.assign(newFile, { imageData });

        return fileWithImageData;
      }),
    );
    return filesWithImages;
  };
  // Desktop Add Media
  const setSelectedImage = async (image: File) => {
    selectedImageURL.value = await handleImage(image);
    selectedImage.value = image;
  };

  const setVideo = async (file: File) => {
    videoFile.value = file;
    if (selectedVideo.value) {
      URL.revokeObjectURL(selectedVideo.value);
    }
    selectedVideo.value = await handleVideo(file);
  };
  const setRawVideoFile = async (files: FileList) => {
    selectedVideoFileList.value = files;
  };

  const setSelectedVideo = (url: string) => {
    selectedVideo.value = url;
  };
  const setVideoPoster = async (file: File) => {
    if (!file) return;
    posterFile.value = file;
    selectedVideoPoster.value = await handleImage(file);
  };

  const setRawVideoPoster = (src: string) => {
    selectedVideoPoster.value = src;
  };

  const setKeyframes = (frames: string[]) => {
    keyframes.value = frames;
  };

  const setSelectionFrames = (frames: string[]) => {
    selectionFrames.value = frames;
  };

  const resetImage = () => {
    selectedImage.value = null;
    selectedImageURL.value = undefined;
  };
  const resetVideo = () => {
    videoFile.value = null;
    selectedVideoPoster.value = undefined;
    selectedVideo.value = '';
    selectedVideoFileList.value = null;
    keyframes.value = [];
    selectionFrames.value = [];
  };

  const updateVideo = (videoUri: string, imageUri: string) => {
    selectedVideo.value = videoUri;
    selectedVideoPoster.value = imageUri;
  };

  const getImageCount = () => {
    // TODO Update when the storytelling posts are supported
    if (selectedImage.value) {
      return 1;
    }
    return 0;
  };

  const getVideoCount = () => {
    // TODO Update when the storytelling posts are supported
    if (selectedVideo.value) {
      return 1;
    }
    return 0;
  };

  return {
    getImageCount,
    getVideoCount,
    inProcessMediaURLS,
    keyframes,
    mediaObjectProgress,
    mediaUploads,
    originalImageResolution,
    originalMediaResolution,
    originalVideoResolution,
    posterFile,
    processedMediaURLS,
    selectedImage,
    selectedImageURL,
    selectedMediaFiles,
    selectedMediaIndex,
    selectedVideo,
    selectedVideoFileList,
    selectedVideoPoster,
    selectionFrames,
    successfulMediaUploads,
    videoFile,
    $reset,
    addImageData,
    getMediaObjectProgressByLtkId,
    getProcessedMediaUrl,
    getSelectedMediaFile,
    getUriByLtkId,
    handleImage,
    handleVideo,
    replaceMediaURLS,
    replaceSelectedMediaFiles,
    resetImage,
    resetVideo,
    saveInProcessMedia,
    saveInProcessMediaURL,
    setInProcessMediaURLS,
    setKeyframes,
    setMediaObjectProgress,
    setMediaUploads,
    setMediaUploadsDesktop,
    setMuted,
    setOriginalImageResolution,
    setOriginalMediaResolution,
    setOriginalVideoResolution,
    setPoster,
    setProcessedMediaURLS,
    setRawVideoFile,
    setRawVideoPoster,
    setSelectedImage,
    setSelectedMediaIndex,
    setSelectedVideo,
    setSelectionFrames,
    setSuccessfulMediaUploads,
    setVideo,
    setVideoPoster,
    updateVideo,
  };
});
