import type { Ref } from 'vue';
import type { Variant } from '@amplitude/experiment-js-client';
import type { Location } from 'vue-router';

/**
 * Enumeration of Experiment Names. A 1:1 match to Amplitude Experiment Keys. Add new Experiments Keys here.
 */
export enum ExperimentName {
  FIRST_TEST = 'sc-238280-adding-amp-to-rsthecom', // Used for unit testing, not a real Experiment
  EXPERIMENT_TEST = 'test-variant', // Used for unit testing, not a real Experiment
  CREATOR_EXPERIENCE_QUESTIONS = 'sc-251191-application-questions',
  MONEYSPOT_FEATURE_FLAG = '289379-moneyspot-feature-flag',
  PUBLISHING = 'post-16-publishing-flow',
  PRODUCT_LINK_EXTENSION = 'disco-279-chrome-extension-product-page',
  UPGRADE_INFOBOX = 'and-post-176-web-posting-flow-infobox',
  APPLICATION_SIGNUP_LOGIN = 'ca-2527-application-singup-and-login',
  ANDROID_HASHTAGS = 'browse-1373-android-hashtags',
  HASHTAGS_PRESTAGED = 'mobile-combine-add-prestaged-header',
  INSTAGRAM_SOCIAL_INTEGRATION_TEST_SANDBOX = 'instagram-social-integration-test-sandbox',
  BULK_UPLOAD = 'acq-893-bulk-upload',
  SOCIAL_INTEGRATION = 'coa-773-social-integration-account-details',
  DESKTOP_PUBLISH = 'acq-desktop-publishing-flow',
  PRODUCT_SEARCH_EXACT_MATCHING = 'srch-200-product-search-exact-matching',
  HIDE_INSTAGRAM_HANDLE_FIELD = 'hide-instagram-handle-field-to-increase-instagram-connections-during-application',
  PRODUCT_SEARCH_DRAWER_ADD_TO_FLOWS = 'srch-509-product-search-drawer-add-to-flows',
}

/**
 * Enumeration of Experiment Types. Flag for... flags (duh, what did you expect?) and Variants for A/B Tests and Multivariate Tests.
 */
export enum ExperimentType {
  VARIANT = 'variant',
  FLAG = 'flag',
}

/**
 * Type for "exotic" 😜 non-standard variants, i.e. variants that are not 'control' or 'treatment'.
 *
 * Add your values to this type to register them as target values when creating Experiments with new Variants.
 *
 * Don't forget to remove them when you're done :p
 */
export type ExoticVariant = 'treatment1';

/**
 * Type for the possible values of a Variant. 'treatment' and 'control' are the default values for A/B Tests and Multivariate Tests.
 */
export type VariantValue = 'treatment' | 'control' | ExoticVariant;

/**
 * Type for the possible values of a Feature Flag. 'on' and 'off' are the default values for Feature Flags.
 */
export type FeatureFlagValue = 'on' | 'off';

/**
 * Utility type for determining the allowed values of an Experiment based on its Type.
 */
type FlagValueOrVariantValue<T extends ExperimentType.FLAG | ExperimentType.VARIANT> = T extends ExperimentType.FLAG
  ? FeatureFlagValue
  : VariantValue;

/**
 * Interface for encapsulating an Experiment. Used to define all Active Experiments for the compiler.
 */
interface Experiment<N extends ExperimentName, T extends ExperimentType, V extends FlagValueOrVariantValue<T>> {
  name: N;
  type: T;
  values: V;
}

/**
 * Union of all Active Experiments. Add new Experiments here with their Name, Type and allowed Values.
 *
 * Don't forget to remove them when you're done :p
 */
export type ActiveExperiment =
  | Experiment<ExperimentName.FIRST_TEST, ExperimentType.FLAG | ExperimentType.VARIANT, VariantValue | FeatureFlagValue>
  | Experiment<ExperimentName.EXPERIMENT_TEST, ExperimentType.VARIANT, VariantValue>
  | Experiment<ExperimentName.CREATOR_EXPERIENCE_QUESTIONS, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.MONEYSPOT_FEATURE_FLAG, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.PUBLISHING, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.PRODUCT_LINK_EXTENSION, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.UPGRADE_INFOBOX, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.APPLICATION_SIGNUP_LOGIN, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.ANDROID_HASHTAGS, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.HASHTAGS_PRESTAGED, ExperimentType.VARIANT, VariantValue>
  | Experiment<ExperimentName.INSTAGRAM_SOCIAL_INTEGRATION_TEST_SANDBOX, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.BULK_UPLOAD, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.SOCIAL_INTEGRATION, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.DESKTOP_PUBLISH, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.PRODUCT_SEARCH_EXACT_MATCHING, ExperimentType.FLAG, FeatureFlagValue>
  | Experiment<ExperimentName.HIDE_INSTAGRAM_HANDLE_FIELD, ExperimentType.VARIANT, VariantValue>
  | Experiment<ExperimentName.PRODUCT_SEARCH_DRAWER_ADD_TO_FLOWS, ExperimentType.FLAG, FeatureFlagValue>;

/**
 * Syntactic sugar for the `name` property of an `ActiveExperiment`.
 */
export type ActiveExperimentName = ActiveExperiment['name'];

/**
 * Utility type for extracting the `type` property of an `ActiveExperiment` based on its `name`.
 */
export type ExtractActiveExperimentType<
  N extends ActiveExperimentName,
  E extends ActiveExperiment = ActiveExperiment,
> = E extends {
  name: N;
  type: infer T;
}
  ? T
  : never;

/**
 * Utility type for extracting the `values` property of an `ActiveExperiment` based on its `name`.
 */
export type ExtractActiveExperimentValues<
  N extends ActiveExperimentName,
  E extends ActiveExperiment = ActiveExperiment,
> = E extends {
  name: N;
  values: infer V;
}
  ? V
  : never;

/**
 * A generic version of the Amplitude SDK's `Variant` type, with the `value` property typed to the allowed values of an `ActiveExperiment`.
 */
export interface ExperimentVariant<N extends ActiveExperimentName> extends Variant {
  value?: ExtractActiveExperimentValues<N>;
}

export interface ExperimentValue<N extends ActiveExperimentName> {
  variant: Ref<ExperimentVariant<N>>;
  /**
   * Reactive utility for checking if the user is using a specific Variant or Feature Flag value. Supports values set in either Amplitude or LocalStorage (for those of you with Ad Blocking on, we ❤️ you).
   *
   * #### Examples
   *
   * ##### Feature Flags
   * ```ts
   * const isFeatureFlagOn = isVariant('on');
   * const isFeatureFlagOff = isVariant('off');
   * ```
   *
   * ##### A/B Testing
   * ```ts
   *  const isVariantControl = isVariant('control');
   *  const isVariantTreatment = isVariant('treatment');
   * ```
   *
   * @param target - The variant or feature flag value to check against
   * @returns A reactive boolean that will update when the user's variant changes
   */
  isVariant: (target: ExtractActiveExperimentValues<N>) => Ref<boolean>;
  exposure: () => void;
}

// TODO: Add (potential) support for generics in Vue 3. This may or may not be possible based on Vue Router v4 support's for TypeScript: https://router.vuejs.org/guide/advanced/meta.html#TypeScript
export type ExperimentMeta = {
  name: ActiveExperimentName;
  type: ExtractActiveExperimentType<ActiveExperimentName>;
  allowed: (ExtractActiveExperimentValues<ActiveExperimentName> | string)[];
  /*
    Default value (e.g. a rsthecom-v2 route as a string: '/products/my-folders') to be used if user should not be exposed to Experiment.
    Note - If none is given to the router it will default to 404.
  */
  control?: Location | string;
};
