export const roundTo = (n: any, digits: number): number => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = parseFloat((n * -1).toFixed(digits));
  }
  return Number(n);
};

const minMax = (min: number, value: number, max: number): number => {
  return Math.max(min, Math.min(value, max));
};

const pctComplete = (value: number, maxValue: number) => {
  return minMax(0, value / maxValue, 1);
};

const quantizeValue = (value: number, step: number) => {
  return Number((value / step) * step);
};

const convertValueToPx = (value: number, min: number, max: number, width: number): number => {
  return minMax(0, ((value - min) / (max - min)) * width, width);
};

const singleUnitValue = (step: number, min: number, max: number, width: number): number => {
  return minMax(0, Math.round((step / (max - min)) * width), width);
};

const convertToValue = (percentComplete: number, min: number, max: number) => {
  return min + percentComplete * (max - min);
};

export { quantizeValue, pctComplete, minMax, convertValueToPx, singleUnitValue, convertToValue };
