import { NavigationGuard } from 'vue-router';
import { auth, buildAuthConfig } from '@/utils/auth';
import { Auth } from '@rscollabs/rs-ui-auth';

/**
 * Navigation guard for authorization code exchange callback.
 *
 * This guard checks the existence of possible errors in the flow
 * and redirects the user back to login with the error description.
 *
 * @param to
 */
export const checkAuthorizationCodeExchangeErrors: NavigationGuard = async (to) => {
  const { error, error_description } = to.query;

  if (error) {
    const consentError = error === 'access_denied' && error_description === 'consent not provided';

    const authConfig = buildAuthConfig({
      consentProvided: consentError ? 'Yes' : 'no',
      error: error_description,
    });

    const auth = new Auth(authConfig);
    await auth.login();
  } else {
    await auth.handleRedirectCallback();
  }
};
