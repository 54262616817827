
import { type Ref, defineComponent, ref, watchEffect, reactive, onMounted, computed, type PropType, watch } from 'vue';
import RwTrimmerKnob from './RwTrimmerKnob.vue';
import { toTime, toSeconds } from '../../utils/toSeconds';

export default defineComponent({
  name: 'LtkSlider',
  model: {
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    start: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 50,
    },
    current: {
      type: Number,
      default: 50,
    },
    keyframes: {
      type: Array as PropType<string[]>,
      required: true,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['changed', 'updated:modelValue'],
  components: {
    RwTrimmerKnob,
  },
  setup(props, { emit }) {
    const state = reactive({
      start: 0,
      end: 100,
      current: 0,
    });
    const slider: Ref<HTMLElement | null> = ref(null);
    const innerFrame: Ref<HTMLElement | null> = ref(null);
    const outerFrame: Ref<HTMLElement | null> = ref(null);

    onMounted(() => {
      state.start = props.start;
      state.end = props.duration;
      state.current = props.current;
    });

    watch(
      () => props.current,
      (newVal) => {
        state.current = newVal;
      },
    );
    watchEffect(() => {
      emit('changed', {
        start: typeof props.start === 'string' ? toTime(state.start) : state.start,
        end: typeof props.duration === 'string' ? toTime(state.end) : state.end,
        current: typeof props.current === 'string' ? toTime(state.current) : state.current,
      });
      if (state.start >= toSeconds(props.duration) + props.step) {
        state.start = toSeconds(props.duration) - props.step;
        state.end = state.start + props.step;
      }
      if (state.end - props.step <= state.start) {
        state.end = state.start + props.step;
      }

      if (state.current >= state.end) {
        state.current = state.end;
      }
      if (state.current < state.start) {
        state.current = state.start;
      }
    });

    const startState = ref({
      computedX: 0,
      handleWidth: 0,
      maxX: 0,
    });

    const endState = ref({
      computedX: 0,
      handleWidth: 0,
      maxX: 0,
    });

    // Get detailed values for the start and end handles to use for advanced styling.
    const startChange = ({ computedX, handleWidth, maxX }: any) => {
      startState.value = {
        computedX,
        handleWidth,
        maxX,
      };
    };

    const endChange = ({ computedX, handleWidth, maxX }: any) => {
      endState.value = {
        computedX,
        handleWidth,
        maxX,
      };
    };

    // Style the inner frame between the start and end handles.
    const frameStyleInner = computed(() => {
      return {
        position: 'absolute',
        left: startState.value.computedX + 'px',
        right: endState.value.maxX - endState.value.computedX + 'px',
      };
    });

    // Greyscale overlay for the start and end handles.
    const frameStyleOuterStart = computed(() => {
      return {
        position: 'absolute',
        left: 0,
        right: startState.value.maxX - startState.value.computedX + 'px',
      };
    });

    const frameStyleOuterEnd = computed(() => {
      return {
        position: 'absolute',
        left: endState.value.computedX + 'px',
        right: 0,
      };
    });

    const calcX = (index: number) => {
      const x = 33 * index;
      return x - 5.5;
    };

    return {
      state,
      slider,
      toSeconds,
      innerFrame,
      outerFrame,
      startChange,
      endChange,
      frameStyleInner,
      frameStyleOuterStart,
      frameStyleOuterEnd,
      calcX,
    };
  },
});
