import { Auth, AuthOptions } from '@rscollabs/rs-ui-auth';
import { AuthorizationParams } from '@auth0/auth0-spa-js';

export const buildAuthConfig = (authorizationParams: AuthorizationParams = {}) => {
  const opts = window.rewardStyle;

  const authConfig: AuthOptions = {
    sigil: {
      idTokenCookie: opts?.ID_TOKEN_COOKIE || 'sigil_id_token',
      accessTokenCookie: opts?.ACCESS_TOKEN_COOKIE || 'sigil_access_token',
      loginUrl: opts?.SIGIL_URL,
      logoutUrl: opts?.LEGACY_HOME_PAGE,
    },
  };

  if (opts?.AUTH0) {
    authConfig.auth0 = {
      clientOptions: {
        clientId: opts.AUTH0.clientId,
        domain: opts.AUTH0.domain,
        authorizationParams: {
          audience: opts.AUTH0.audience,
          redirect_uri: opts.AUTH0.redirectUri,
          consentProvided: 'no',
          prompt: 'login',
          screen_hint: 'login',
          scope: 'openid profile email ltk.publisher',
          ...authorizationParams,
        },
        cacheLocation: 'localstorage',
        useCookiesForTransactions: true,
        useRefreshTokens: true,
      },
      // Called after successful code exchange
      redirectCallback: (target: string) => {
        window.location.replace(target);
      },
    };
  }

  return authConfig;
};

/**
 * Default Auth instance to be used throughout the app
 *
 * In cases where you need to customize the configuration,
 * for example when we want to redirect the user to sign up,
 * you can create another Auth instance with your custom configuration,
 * but in any other case, you should use this default instance.
 */
export const auth = new Auth(buildAuthConfig());

declare module '@auth0/auth0-spa-js' {
  export interface AuthorizationParams {
    consentProvided?: 'Yes' | 'no';
  }
}
