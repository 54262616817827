import Vue from 'vue';
import Cookie from 'js-cookie';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { BASE, HOME, HOMEPAGE, SECURITY_TXT, NOT_FOUND, LOGOUT } from '@/utils/router/namedRoutes';
import analyticsRoutes from '@/rs-analytics/router';
import applicationRoutes from '@/rs-application/router';
import socialRoutes from '@/rs-social/router';
import productsRoutes from '@/rs-products/router';
import legalRoutes from '@/rs-legal/router';
import homepageRoutes from '@/rs-homepage/router';
import accountRoutes from '@/rs-account/router';
import accountSetupRoutes from '@/rs-account-setup/router';
import publishRoutes from '@/rs-publish/router';
import foldersRoutes from '@/rs-folders/router';
import widgetsRoutes from '@/rs-widgets/router';
import legacyHomePageLink from '@/utils/legacyHomePageLink';
import desktopPublishRoutes from '@/rs-desktop-publish/router';
import { auth, buildAuthConfig } from '@/utils/auth';
import { Layout } from '@/layouts/types';
import { scrollUnderBy } from '@/utils/scrollUnderBy';
import { ExperimentMeta } from '@/types/experiments';
import { TitleMeta } from '@/composables/usePageTitle';
import { registerNavigationGuards } from '@/router/navigation-guards';
import { Auth } from '@rscollabs/rs-ui-auth';
import { checkAuthorizationCodeExchangeErrors } from '@/router/navigation-guards/beforeEach/authentication/checkAuthorizationCodeExchangeErrors';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...analyticsRoutes,
  ...applicationRoutes,
  ...socialRoutes,
  ...productsRoutes,
  ...legalRoutes,
  ...homepageRoutes,
  ...accountRoutes,
  ...accountSetupRoutes,
  ...foldersRoutes,
  ...widgetsRoutes,
  ...desktopPublishRoutes,
  ...publishRoutes,
  {
    path: '',
    name: BASE,
    beforeEnter: (to, from, next) => {
      next({ name: HOMEPAGE });
    },
  },
  // Redirect /home to root (since /app/home -> /home at the proxy level).
  {
    path: '/home',
    name: HOME,
    beforeEnter: () => {
      window.location.href = '/';
    },
  },
  // Security requested this - https://securitytxt.org/
  {
    path: '/.well-known/security.txt',
    name: SECURITY_TXT,
    beforeEnter: () => {
      window.location.href = '/.well-known/security.txt';
    },
    meta: {
      auth: {
        required: false,
      },
    },
  },
  {
    name: 'Login',
    path: '/login',
    beforeEnter: async () => {
      const isLoggedIn = await auth.isAuthenticated();

      if (!isLoggedIn) {
        await auth.login({
          options: {
            appState: {
              target: 'https://' + window.location.host + '/home',
            },
          },
          next: 'https://' + window.location.host + '/home',
        });
      } else {
        window.location.href = 'https://' + window.location.host + '/home';
      }
    },
    meta: {
      auth: {
        required: false,
      },
    },
  },
  {
    name: 'LoginCallback',
    path: '/login/callback',
    beforeEnter: checkAuthorizationCodeExchangeErrors,
    meta: {
      auth: {
        required: false,
      },
    },
  },
  {
    name: LOGOUT,
    path: '/logout',
    meta: {
      statusCheck: {
        required: false,
      },
    },
    beforeEnter: async () => {
      await auth.logout({
        logoutParams: {
          returnTo: 'https://' + window.location.host + '/home',
        },
        // Todo: Delete this redirect interception once we remove rewardstyle-v2 as an auth proxy
        //  Right now this only exists to clear out sigil cookies and users only need to do it once
        openUrl(target) {
          window.location.replace(Cookie.get('sigil_id_token') !== undefined ? legacyHomePageLink('/logout') : target);
        },
      });
    },
  },
  // Redirect all other other requests to the 404.
  {
    path: '*',
    name: NOT_FOUND,
    beforeEnter: () => {
      window.location.href = '/404.html';
    },
    meta: {
      auth: {
        required: false,
      },
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      setTimeout(() => {
        const element = document.querySelector(to.hash);
        if (!element) throw new Error('Element not found');
        scrollUnderBy(element, { behavior: from.path === to.path ? 'smooth' : 'auto' });
      }, 500);
    } else if (from.path === to.path && !to.hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }
  },
});

registerNavigationGuards(router);

interface Breadcrumb {
  label: string;
  to: string;
  id: string;
}

declare module 'vue-router' {
  export interface RouteMeta {
    title?: TitleMeta;
    layout?: Layout;
    experiment?: ExperimentMeta;
    useExchangeRate?: boolean;
    breadcrumbs?: Breadcrumb[] | ((this: Route) => Breadcrumb[]);

    // Set for custom view names in DataDog if needed to override the route name
    viewName?: string;

    // other optional meta attributes...
    statusCheck?: {
      required: boolean;
    };
  }
}
export default router;
