import { NavigationGuard } from 'vue-router';

import AccountsStore from '@/store/accounts';
import { APPLY_HOME, APPLY_STATUS, HOME, VERIFY_EMAIL } from '@/utils/router/namedRoutes';
import { auth } from '@/utils/auth';
import { UserStatus } from '@/utils/decodeUserStatus';

/**
 * Check user's email_verified status and if they attempt to navigate directly to the page
 * while having their email already verified, push them to the apply page.
 *
 * @param to
 * @param from
 * @param next
 */
export const checkEmailVerificationStatus: NavigationGuard = async function (to, from, next) {
  const idTokenClaims = await auth.getIdTokenClaims();

  // Only allow users to access the page if they haven't verified their email
  if (to.name === VERIFY_EMAIL && idTokenClaims?.email_verified === false) {
    return next();
  }

  // At this point email has been verified so by default redirect to application homepage
  let redirectToPage = APPLY_HOME;

  // Application approved, redirect to homepage
  if (AccountsStore.userStatusRoles.includes(UserStatus.APPROVED)) {
    redirectToPage = HOME;
  }

  return next({ name: redirectToPage, replace: true });
};
