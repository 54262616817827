
import { computed, defineComponent, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useRoute } from 'vue-router/composables';

import AccountsStore from '@/store/accounts';
import { auth } from '@/utils/auth';
import { useRouteChecks } from '@/composables/useRouteChecks';

import TosUpdateModal from '@/components/TosUpdateModal.vue';
import ContributorUpdateModal from '@/components/ContributorUpdateModal.vue';
import { PUBLISH_LTK } from '@/utils/router/namedRoutes';

export default defineComponent({
  name: 'AppModalDrawer',
  emits: ['active'],
  components: {
    TosUpdateModal,
    ContributorUpdateModal,
  },
  setup(props, context) {
    const $route = useRoute();

    const { doesRoutePassChecks: doesTosPassRouteChecks } = useRouteChecks($route, 'TOS_UPDATE_MODAL');

    const canShowTosModal = computedAsync(async () => {
      const hasPassedRouteChecks = doesTosPassRouteChecks.value;
      const needsAcceptTerms = AccountsStore.user.needs_accept_terms;
      const isAuthenticated = await auth.isAuthenticated();
      return isAuthenticated && hasPassedRouteChecks && needsAcceptTerms;
    }, false);

    const { doesRoutePassChecks: doesContributorPassRouteChecks } = useRouteChecks($route, 'CONTRIBUTOR_UPDATE_MODAL');

    const canShowContributorModal = computedAsync(async () => {
      const isValidRoute = doesContributorPassRouteChecks.value;
      const isEligibleUser = !AccountsStore.user.is_owner && !AccountsStore.user.address.country;
      const isNotPublishFlow = $route.name !== PUBLISH_LTK;

      const [idTokenClaims, isAuthenticated] = await Promise.all([auth.getIdTokenClaims(), auth.isAuthenticated()]);
      const isNotImpersonator = idTokenClaims && idTokenClaims.impersonator_id === undefined;
      const emailVerified = idTokenClaims?.email_verified !== false;

      return (
        isAuthenticated && emailVerified && isValidRoute && isEligibleUser && isNotImpersonator && isNotPublishFlow
      );
    }, false);

    const isModalActive = computed(() => canShowTosModal.value || canShowContributorModal.value);

    watch(
      isModalActive,
      () => {
        context.emit('active', isModalActive.value);
      },
      { immediate: true },
    );

    return {
      canShowTosModal,
      canShowContributorModal,
      isModalActive,
    };
  },
});
