import './scss/_colors.scss';
import { toCamelCase, toKebab } from './utils/textTransform';
import RsAlertBar from './components/RsAlertBar/RsAlertBar.vue';
import RsButton from './components/RsButton/RsButton.vue';
import RsButtonGroup from './components/RsButton/RsButtonGroup.vue';
import RsCheckbox from './components/RsCheckbox/RsCheckbox.vue';
import RsModal from './components/RsModal/RsModal.vue';
import RsSidebar from './components/RsSidebar/RsSidebar.vue';
import RsSidebarItem from './components/RsSidebar/RsSidebarItem.vue';
import RsSlideUpCard from './components/RsSlideUpCard/RsSlideUpCard.vue';
import RsBreadcrumbs from './components/RsBreadcrumbs/RsBreadcrumbs.vue';
import RsBreadcrumbItem from './components/RsBreadcrumbs/RsBreadcrumbItem.vue';
import uuid from './utils/uuid';
import useKeyboardFocus from './composables/useKeyboardFocus';
import RsAppStore from './components/RsBadge/RsAppStore.vue';
import RsPlayStore from './components/RsBadge/RsPlayStore.vue';
import RsToggleButton from './components/RsToggleButton/RsToggleButton.vue';
import RsToggleOptions from './components/RsToggleOptions/RsToggleOptions.vue';
import RsImageCropper from './components/RsImageCropper/RsImageCropper.vue';
import RsTip from './components/RsTip/RsTip.vue';
import { RwDialog, RwModal, RwModalBody, RwModalHeader, RwModalFooter, RwOverlay } from './components/RwModal';
import { RwFocusTrap } from './components/RwFocusTrap';
import RsPopover from './components/RsPopover/RsPopover.vue';
import { RwCheckbox } from './components/RwCheckbox';
import { RwChip } from './components/RwChip';
import usePopper from './composables/usePopper';
import RwTrimmer from './components/RwTrimmer/RwTrimmer.vue';
import RwTrimmerKnob from './components/RwTrimmer/RwTrimmerKnob.vue';
import { useSlider } from './components/RwTrimmer/useSlider';

const components: any = {
  RsAlertBar,
  RsButton,
  RsButtonGroup,
  RsCheckbox,
  RsModal,
  RsSidebar,
  RsSidebarItem,
  RsSlideUpCard,
  RsAppStore,
  RsPlayStore,
  RsBreadcrumbs,
  RsBreadcrumbItem,
  RsToggleButton,
  RsToggleOptions,
  RsTip,
  RwDialog,
  RwModal,
  RwModalBody,
  RwModalHeader,
  RwModalFooter,
  RwOverlay,
  RwFocusTrap,
  RsPopover,
  RwCheckbox,
  RwChip,
  RsImageCropper,
  RwTrimmer,
  RwTrimmerKnob,
  useSlider,
};

const componentsDesc = Object.keys(components).map((item) => {
  const component = components[item];
  return {
    name: component?.name || 'rs-comp',
    component,
  };
});

const rsInstall = (app: any) => {
  if (!app) {
    return;
  }
  componentsDesc.forEach((item) => {
    const kebabCaseName = toKebab(item.name);
    const camelCaseName = toCamelCase(`-${kebabCaseName}`);
    const registerComponent = item.component;
    app.component(kebabCaseName, registerComponent);
    app.component(camelCaseName, registerComponent);
  });
};

export {
  RsAppStore,
  RsPlayStore,
  uuid,
  useKeyboardFocus,
  rsInstall,
  RsAlertBar,
  RsButton,
  RsButtonGroup,
  RsCheckbox,
  RsModal,
  RsSidebar,
  RsSidebarItem,
  RsSlideUpCard,
  RsBreadcrumbs,
  RsBreadcrumbItem,
  RsToggleButton,
  RsToggleOptions,
  RsTip,
  usePopper,
  RwDialog,
  RwModal,
  RwModalBody,
  RwModalHeader,
  RwModalFooter,
  RwOverlay,
  RwFocusTrap,
  RsPopover,
  RwCheckbox,
  RwChip,
  RsImageCropper,
  RwTrimmer,
  RwTrimmerKnob,
  useSlider,
};
