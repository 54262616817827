import {
  UserEventType,
  PublishingReferrerParams,
  ExtractEventData,
  UserEvent,
  UserEventsPublisher,
} from '../../utils/UserEvents/types';
import { computed } from 'vue';
import AccountsStore from '../../store/accounts';
import ProfilesStore from '../../store/profiles';

import type { Route } from 'vue-router';

export const usePublishingAnalytics = (userEvents: UserEventsPublisher, route?: Readonly<Route>) => {
  const profileStore = ProfilesStore.profile;
  const accountStore = AccountsStore;
  const profileData = computed(() => {
    return {
      'profile-id': profileStore.id,
      'profile-name': profileStore.display_name,
      'publisher-name': profileStore.full_name,
      'rs-account-id': profileStore.rs_account_id,
    };
  });

  const accountData = computed(() => {
    return {
      'access-level': accountStore.user.is_owner ? 'OWNER' : 'CONTRIBUTOR',
      'publisher-id': accountStore.user.id,
    };
  });

  const data: Partial<PublishingReferrerParams> = {
    page: route?.name || '',
    referrer: 'publish',
    'root-referrer': 'publish-ltk',
    'post-type': 'timeline',

    ...profileData.value,
    ...accountData.value,
  };

  const publishingEvent = <T extends UserEventType>(
    type: T,
    additionalData?: Partial<ExtractEventData<UserEvent, T>>,
  ) => {
    const payload = <ExtractEventData<UserEvent, T>>{
      ...data,
      ...additionalData,
    };
    userEvents.sendEvent(type, payload);
  };

  return { data, publishingEvent };
};
